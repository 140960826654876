import React from 'react';
import { imgStar } from './assets';
import CurrencyList from "currency-list";
import { setStorageData } from '../../framework/src/Utilities';
import { Message } from '../../framework/src/Message';
import MessageEnum, { getName } from '../../framework/src/Messages/MessageEnum';
import { runEngine } from '../../framework/src/RunEngine';

export function getStars(avgReview: number | null) {
    return avgReview ? Array.from(Array(Math.round(avgReview))).map((item, index) =>
        <img key={index} src={imgStar} alt='image star' />
    ) : ''
}

export const isPlural = (amount: number, word: string, ending = "s") =>
    amount === 1 ? word : word + ending;

export const findCurrByCode = (currCode: string) => Object.values(CurrencyList.getAll("en_US")).find(
    (curr: Record<string, string>) => {
        return curr.code === currCode
    }
)?.symbol || '$';

export const getAndSaveCurrency = (responseJson: any) => {
    const currCode = responseJson?.data?.attributes.currency;
    const currancy = findCurrByCode(currCode);
    setStorageData('currency', currancy);

    return currancy;
}

export const createFakeApiMessage = (instanceVar: string, response: Record<string, any>) => {
    const getUserDataEmpImages = new Message(
        getName(MessageEnum.RestAPIResponceMessage)
    );

    getUserDataEmpImages.addData(
        getName(MessageEnum.RestAPIResponceDataMessage),
        getUserDataEmpImages.messageId
    )

    getUserDataEmpImages.addData(
        getName(MessageEnum.RestAPIResponceSuccessMessage),
        response
    );
    instanceVar = getUserDataEmpImages.messageId;
    runEngine.sendMessage("Unit test", getUserDataEmpImages);
}
