import React from "react";
// Customizable Area Start

import {
  Box,
  Button,
  Typography,
  Select,
  MenuItem,
  createTheme,
  ThemeProvider,
} from "@material-ui/core";

import ChevronLeftRoundedIcon from '@material-ui/icons/ChevronLeftRounded';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from "@fullcalendar/interaction";
import dayjs from "dayjs";
import './calendar.css';

import Ordermanagement1Controller, {
  allServiceType,
  Props,
} from "./Ordermanagement1Controller";
import {
  bookingSchedule,
  defaultProfile,
} from './assets';
import './select.css';
import { BookingWrapper } from './components/BookingWrapper';
import BookingModal from './components/BookingModal';

const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    body1: {
      fontFamily: "Ubuntu",
    },
  },
});
// Customizable Area End

export default class Ordermanagement1 extends Ordermanagement1Controller {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    this.gotoMyBooking = this.gotoMyBooking.bind(this);
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <>
        <ThemeProvider theme={theme}>
          <BookingWrapper navigation={this.props.navigation} >
            <Box display='flex' mt={5} justifyContent='space-between'>
              <Box position='relative' width='70%'>
                <Button
                  data-test-id={"btnAcceptTerms"}
                  type='submit'
                  variant="contained"
                  color="primary"
                  className="agreeButton"
                  style={{
                    width: 'auto',
                    fontSize: '16px',
                    padding: '5px 15px',
                    height: 'auto',
                    position: 'absolute',
                    right: '32px',
                    top: '24px',
                    backgroundColor: "#398378",
                    fontWeight: 700,
                    textTransform: "none",
                    fontFamily: "Ubuntu"
                  }}
                  startIcon={<ChevronLeftRoundedIcon style={{ color: '#ffffff' }} />}
                  onClick={this.gotoMyBooking}
                >
                  My Booking
                </Button>
                <FullCalendar
                  plugins={[dayGridPlugin, interactionPlugin]}
                  initialView="dayGridMonth"
                  headerToolbar={{
                    start: 'title,prev,next',
                    center: '',
                    end: 'myBookingsButton'
                  }}
                  titleFormat={
                    { month: 'long', year: '2-digit' }
                  }
                  firstDay={1}
                  events={this.state.scheduledBookings}
                  eventClick={(info) =>
                    this.setScheduleCancelModal(info.event._def.publicId)
                  }
                  datesSet={async (event) =>
                    this.setState({
                      scheduleDate: dayjs(`01 ${event.view.title}`).format(
                        "YYYY-MM-DD"
                      ),
                    })
                  }
                />
              </Box>

              <Box width='27%'>
                <Select
                  data-select-id="service-type-select"
                  fullWidth
                  variant="outlined"
                  value={this.state.scheduleServiceType}
                  displayEmpty
                  IconComponent={() =>
                    <PlayArrowIcon
                      fontSize='small'
                      style={{
                        color: '#ffffff',
                        right: '10%',
                        position: 'absolute'
                      }} />}
                  renderValue={
                    this.state.scheduleServiceType ?
                      undefined :
                      () =>
                        <div
                          style={{
                            color: '#FFFFFF',
                            fontWeight: 700,
                            fontFamily: "Ubuntu"
                          } as React.CSSProperties
                          }
                        >
                          Select Type
                        </div>
                  }
                  onChange={this.setScheduleServiceType}
                  style={{
                    height: '36px',
                    backgroundColor: '#398378',
                    color: '#ffffff',
                    fontWeight: 700,
                    borderRadius: "8px",
                  }}
                  MenuProps={{
                    anchorOrigin: {
                      vertical: "bottom",
                      horizontal: "left"
                    },
                    getContentAnchorEl: null,
                    style: {
                      maxHeight: '50vh'
                    },
                  }}
                >
                  {this.getScheduleCategoriesList().map((arrItem, index) => <MenuItem key={index} value={arrItem}>{arrItem}</MenuItem>)}
                </Select>
                {this.state.showBookings ?
                  <Box mt={2} maxHeight='55vh' overflow='auto'>
                    {this.state.scheduledBookings
                      .filter((booking) =>
                        this.state.scheduleServiceType === booking.category ||
                        this.state.scheduleServiceType === allServiceType
                      )
                      .map((card) =>
                        <Box key={card.id} p={1} mb={1} borderRadius='8px' display='flex' style={{ backgroundColor: '#FFFFFF' }}>
                          <Box height={56} maxWidth={56} minWidth={56} mr={2}>
                            <img
                              src={card.photo || defaultProfile}
                              alt='Profile Photo'
                              style={{
                                objectFit: 'cover',
                                width: '100%',
                                height: '100%',
                                borderRadius: '8px'
                              }} />
                          </Box>
                          <Box overflow='hidden' display='flex' justifyContent='space-between' flexDirection='column'>
                            <Typography
                              style={{
                                whiteSpace: 'nowrap',
                                textOverflow: 'ellipsis',
                                overflow: 'hidden'
                              }}
                            >{card.name}{' '}
                              <span style={{
                                color: card.color
                              }}>
                                {`(${card.title})`}
                              </span>
                            </Typography>
                            <Typography
                              style={{
                                color: '#64748B'
                              }}
                            >{card.time}
                            </Typography>
                          </Box>
                        </Box>
                      )}
                  </Box> :
                  <Box px={1} py={2} pb={4} mt={2} borderRadius='8px' style={{ backgroundColor: '#FFFFFF' }}>
                    <img src={bookingSchedule} alt='Booking Schedule' style={{
                      maxWidth: '100%'
                    }} />
                  </Box>}
              </Box>

            </Box>
          </BookingWrapper>
          <BookingModal
            openRescheduleModal={this.state.openRescheduleModal}
            setOpenRescheduleModal={this.setOpenRescheduleModal}
            navigation={this.props.navigation}
            id={`${this.state.selectedBookingId}`}
            modalContent={{
              title: 'Booking Detail',
              buttonTitle: 'Cancel Booking',
            }}
            modalData={{
              name: this.state.selectedSchedule.name,
              serviceName: this.state.selectedSchedule.title,
              duration: {
                id: 0,
                duration: this.state.selectedSchedule.duration,
                price: this.state.selectedSchedule.price,
                dates: [],
                timeslots: []
              },
              slot: this.state.selectedSchedule.time.split(", ")[1],
              date: dayjs(this.state.selectedSchedule.start).format("DD MMMM"),
              price: Number(this.state.selectedSchedule.price),
            }}
          />
        </ThemeProvider>
        {/* : <Loader loading />} */}
      </>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
// Customizable Area End
