import React, { useEffect, useState } from 'react'
import {
  Box,
  Button,
} from "@material-ui/core";
import { styled } from "@material-ui/core/styles";
import { getStorageData } from '../../framework/src/Utilities';

const Logo = require("./logo_white.svg");
const avatar = require("./avtarHeader.png");

function navigateToSpaUserProfiles() {
  window.location.href = '/SpaUserProfile';
}

function goToLogin() {
  window.location.href = '/';
}

export function AppHeader(props: any) {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [profileImageUrl, setProfileImageUrl] = useState(avatar);

  const handleProfileClick = () => {
    if (props.onProfileClick) {
      props.onProfileClick();
    } else {
      navigateToSpaUserProfiles();
    }
  };

  useEffect(() => {
    const checkAuthToken = async () => {
      const authToken = await getStorageData('authToken');
      setIsLoggedIn(!!authToken);

      const storedProfileUrl = await getStorageData("avatarImageUrl");
      if (storedProfileUrl) {
        setProfileImageUrl(storedProfileUrl);
      }
    };

    checkAuthToken();
  }, []);

  return (
    <Box style={styles.container as React.CSSProperties}>
      <img src={Logo} alt={""} style={{ width: '105px', height: '43px', objectFit: 'cover' }} />
      {isLoggedIn ? (
        <img
          src={props.profileImageUrl || profileImageUrl || avatar}
          alt="User Profile"
          style={styles.profileImage}
          onClick={handleProfileClick}
        />
      ) : (
        <ButtonComponent>
          <Button
            className='login'
            onClick={() => goToLogin()}
          >
            Login
          </Button>
        </ButtonComponent>
      )}
    </Box>)
}
const ButtonComponent = styled(Button)({
  '& .login': {
    display: 'flex',
    // width: '120px',
    height: '44px',
    padding: '10px 16px', borderRadius: '8px',
    background: 'var(--Primary, #398378)',
    fontFamily: 'Ubuntu',
    textTransform: 'none',
    color: '#fff'
  },
})
const styles = {
  container: {
    alignItems: 'center',
    justifyContent: 'space-between',
    minHeight: '56px',
    backgroundColor: '#000',
    flexDirection: 'row',
    paddingLeft: '141px',
    paddingRight: '141px',
    display: 'flex'
  },
  text: {
    fontSize: 36,
    fontWeight: '600',
  },
  profileImage: {
    width: '40px',
    height: '40px',
    borderRadius: '50%',
    cursor: 'pointer'
  }
}