import React from "react";

import {
  Container,
  Box,
  Input,
  Button,
  InputLabel,
  Typography,
  InputAdornment,
  IconButton,
  // Customizable Area Start
  styled,
  Modal
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import CloseIcon from '@material-ui/icons/Close';
import { GalleryBlock } from '../../../components/src/GalleryBlock.web';

const ButtonComponent = styled(Box)({
  display: "flex",
  flexDirection: "row",
  alignItems: "center !important",
  justifyContent: 'space-between',
  padding: "30px 0px",
  '& .regButton': {
    display: 'flex',
    width: '150px',
    height: '65px',
    borderRadius: '8px',
    fontSize: '12px',
    background: 'var(--Primary, #398378)',
    fontFamily: 'Ubuntu',
    textTransform: 'none',
    textAlign: 'center',
    padding: 0
  }
})
// Customizable Area End

import Ordermanagement1Controller, {
  Props,
  configJSON,
} from "./Ordermanagement1Controller";
import { TypographyView, styles } from './styledFont';
import { startBooking } from './assets';
import { BookingWrapper } from './components/BookingWrapper';
import CancelModal from './components/CancelModal';
import { isPlural } from "../../../components/src/helpers";
import Loader from "../../../components/src/Loader.web";

export default class Ordermanagement1 extends Ordermanagement1Controller {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  navigationMap = {
    'Home': 'LandingPage',
    'Spa Profile': 'SpaUserProfile',
  }
  // Customizable Area End

  render() {
    const buttons = [
      {
        title: 'Cancel',
        handler: (id: number) => this.setOpenCancelModal.bind(this)(id),
      },
      {
        title: 'View Details',
        handler: (id: number) => this.gotoBookingDetails.bind(this)(id),
      }
    ];

    this.state.viewMode !== 'booked' && buttons.shift();
    return (
      // Customizable Area Start
      <>
        <BookingWrapper navigation={this.props.navigation} navigationMap={this.navigationMap}>
          <ButtonComponent style={{
            display: 'flex',
            justifyContent: 'space-between',
            width: '100%'
          }}>
            {/* Statuses buttons */}
            <Box display='flex' gridGap={16}>
              <Button
                data-test-id='completed-btn'
                variant="contained"
                color="primary"
                onClick={() => this.setState({ viewMode: 'completed' })}
                className="regButton"
                style={this.getButtonBorder('completed')}
              >
                <Box width='100%' color='#047854' textAlign='left' style={{ width: 'fit-content' }} >
                  <Typography
                    style={{
                      fontWeight: 700
                    }}>
                    Completed
                  </Typography>
                  <Typography
                    style={{
                      fontWeight: 400
                    }}>
                    {`${this.state.bookingsCount.completed} ${isPlural(this.state.bookingsCount.completed, "Appointment")}`}
                  </Typography>
                </Box>
              </Button>
              <Button
                data-test-id='booked-btn'
                variant="contained"
                color="primary"
                onClick={() => this.setState({ viewMode: 'booked' })}
                className="regButton"
                style={this.getButtonBorder('booked')}

              >
                <Box width='100%' color='#D97706' textAlign='left' style={{ width: 'fit-content' }}>
                  <Typography
                    style={{
                      fontWeight: 700
                    }}>
                    Upcoming
                  </Typography>
                  <Typography
                    style={{
                      fontWeight: 400
                    }}>
                    {`${this.state.bookingsCount.booked} ${isPlural(this.state.bookingsCount.booked, "Appointment")}`}
                  </Typography>
                </Box>
              </Button>
              <Button
                data-test-id='cancel-btn'
                variant="contained"
                color="primary"
                onClick={() => this.setState({ viewMode: 'cancelled' })}
                className="regButton"
                style={this.getButtonBorder('cancelled')}
              >
                <Box width='100%' color='#DC2626' textAlign='left' style={{ width: 'fit-content' }}>
                  <Typography
                    style={{
                      fontWeight: 700
                    }}>
                    Cancelled
                  </Typography>
                  <Typography
                    style={{
                      fontWeight: 400
                    }}>
                    {`${this.state.bookingsCount.cancelled} ${isPlural(this.state.bookingsCount.cancelled, "Appointment")}`}
                  </Typography>
                </Box>
              </Button>
            </Box>
            {/* UI functional buttons */}
            <Box display='flex' gridGap={10}>
              <Button
                data-test-id='schedule-btn'
                variant="contained"
                color="primary"
                onClick={() => this.gotoCalendar()}
                className="regButton"
                style={{
                  fontSize: '16px'
                }}
              >
                View Scheduling
              </Button>
              <Button
                data-test-id='booking-form-btn'
                variant="contained"
                color="primary"
                onClick={() => this.gotoBookingForm()}
                className="regButton"
                style={{
                  background: 'var(--Primary, #D9EEEB)',
                  color: '#047854'
                }}
              >
                <AddCircleOutlineIcon
                  className="AddIconStyle" style={{
                    color: '#398378',
                    maxHeight: '22px'
                  }} />
                <Typography style={{
                  marginLeft: '4px',
                  fontWeight: 700
                }}>
                  New Booking
                </Typography>
              </Button>
            </Box>
          </ButtonComponent>
          {/* Not found picture */}
          {this.state.loading ? (
            <Loader loading />
          ) : (
            <Box>
              {this.state.customerProfilesPage.length ?
                <Box>
                  <GalleryBlock
                    products={this.state.customerProfilesPage}
                    buttons={buttons}
                    narrowImages
                  />
                  {/* Pagination */}
                  {!!this.state.lastPage && (
                    <Box
                      display='flex'
                      alignItems='center'
                      gridGap={12}
                      mt={3}
                      margin='auto'
                      width='fit-content'
                    >
                      <Box
                        data-test-id='pagination-back-btn'
                        onClick={this.goBack.bind(this)}
                        {...styles.arrowWrapper}
                        border={`1px solid ${this.getGoBackColor()}`}
                      >
                        <ArrowBackIosIcon
                          style={{
                            width: '15px',
                            marginLeft: '6px',
                            color: this.getGoBackColor()
                          }} />
                      </Box>
                      <Box fontSize='18px' color='#64748B' fontFamily='Ubuntu'>
                        {this.state.currentPage} of {this.state.lastPage}
                      </Box>
                      <Box
                        data-test-id='pagination-next-btn'
                        onClick={this.goForward.bind(this)}
                        {...styles.arrowWrapper}
                        border={`1px solid ${this.getGoForwardColor()}`}
                      >
                        <ArrowForwardIosIcon
                          style={{
                            width: '15px',
                            color: this.getGoForwardColor()
                          }} />
                      </Box>
                    </Box>
                  )}
                </Box>
                :
                <Box style={{
                  background: `url(${startBooking}) no-repeat center/contain`,
                  minHeight: '300px',
                  position: 'relative'
                }}>
                  <Button
                    data-test-id={"backBtn"}
                    variant="contained"
                    disabled
                    // onClick={() => this.goBack()}
                    className="regButton"
                    style={{
                      position: 'absolute',
                      background: 'var(--Primary, #D9EEEB)',
                      color: '#145249',
                      width: '130px',
                      boxShadow: 'none',
                      top: '22%',
                      right: '15%'
                    }}
                  >
                    NO BOOKING FOUND!
                  </Button>
                </Box>
              }
            </Box>
          )}
        </BookingWrapper>
        <CancelModal
          openCancelModal={this.state.openCancelModal}
          setOpenCancelModal={this.setOpenCancelModal}
          onCancelBooking={this.cancelBooking}
          bookedID={this.state.selectedBookingId}
          navigation={this.props.navigation}
          userName={this.selectedBookingUserName()}
          id=''
        />
      </>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
// Customizable Area End
