import React from "react";
// Customizable Area Start
import {
  Button,
  Typography,
  Box,
  TextField,
  InputLabel,
  InputAdornment,
  Chip,
  Select,
  MenuItem
} from "@material-ui/core";
import { styled } from "@material-ui/core/styles";
import { Formik } from "formik";
import * as Yup from "yup";

import { TypographyView, styles } from './styledFont';
import { AppHeader } from '../../../components/src/AppHeader.web';
import { BreadCrumbs } from '../../../components/src/BreadCrumbs.web';
import { AppFooter } from '../../../components/src/AppFooter.web';
import { arrowDown, calendar, imgPendingClockGrey } from './assets';
import { Autocomplete } from '@material-ui/lab';
import DatePicker, { DateObject } from 'react-multi-date-picker';

import Ordermanagement1Controller, { Props, initBookingFormValues } from './Ordermanagement1Controller';
import { RegistrationFormView } from './regView';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { initCatalogues } from "../../settings1/src/Settings1Controller";
import { getStorageData } from "../../../framework/src/Utilities";
import moment from 'moment';

const configJSON = require('./config');

export const testArray = [
  'Test value 01',
  'Test value 02',
  'Test value 03',
  'Test value 04',
  'Test value 05',
  'Test value 06',
  'Test value 07',
  'Test value 09',
  'Test value 10',
  'Test value 11',
  'Test value 12',
  'Test value 13',
  'Test value 14',
  'Test value 15',
  'Test value 16',
];

const genders = [
  'Male',
  'Female'
];

const ButtonComponent = styled(Box)({
  display: "flex",
  gridGap: '32px',
  flexDirection: "row",
  alignItems: "center !important",
  justifyContent: 'space-between',
  padding: "0 0 30px",
  '& .regButton': {
    display: 'flex',
    width: '150px',
    height: '65px',
    borderRadius: '8px',
    fontSize: '16px',
    background: 'var(--Primary, #398378)',
    fontFamily: 'Ubuntu',
    textTransform: 'none',
    textAlign: 'center',
    padding: 0
  }
});

// Customizable Area End
export default class OrdermanagementForm1 extends Ordermanagement1Controller {
  calendarSlotRef: React.RefObject<{
    openCalendar: () => void;
    closeCalendar: () => void;
  }> = React.createRef();
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    this.getCatalogues();
    // Customizable Area End
  }

  // Customizable Area Start
  navigationMap = {
    'Home': 'LandingPage',
    'Spa Profile': 'SpaUserProfile',
    'My Booking': 'SpaBooking',
  }
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <TypographyView>
        <Box style={styles.SafeAreaView} className='landing-venue-block'>
          <AppHeader />
          <Box py={6} px='140px'>
            {/* Heading */}
            <BreadCrumbs
              items={['Home', 'Spa Profile', 'My Booking', 'Add Booking']}
              targetColor='#398378'
              navigationMap={this.navigationMap}
              navigate={this.handleNavigate}
            />
            <Typography
              style={{
                fontFamily: 'Ubuntu',
                fontSize: '32px',
                fontWeight: 700,
                padding: '16px 0',
              }}
            >Add New Booking</Typography>
            <Formik
              initialValues={initBookingFormValues}
              validationSchema={Yup.object().shape({
                name: Yup.string().required(configJSON.emptyName),
                phone: Yup.number().typeError(configJSON.phoneValidationError).required(configJSON.emptyNumber),
                gender: Yup.string().required(configJSON.emptyGender),
                email: Yup.string().required(configJSON.emptyEmail).email(configJSON.emailValidationError),
                serviceName: Yup.object().shape({
                  name: Yup.string().required(configJSON.emptyService),
                }),
                serviceCategory: Yup.object().shape({
                  name: Yup.string().required(configJSON.emptyCategory),
                }),
                serviceSubCategory: Yup.object().shape({
                  name: Yup.string().required(configJSON.emptySubCategory),
                }),
                date: Yup.string().required(configJSON.emptyDate)
                  .test('futureDate', configJSON.futureDate, (value) => value && new Date(value) > new Date()),
                duration: Yup.object().shape({
                  duration: Yup.string().required(configJSON.emptyDuration),
                }),
                slots: Yup.string().required(configJSON.emptySlots),
              })}
              onSubmit={async (values) => {
                const {
                  name,
                  phone,
                  email,
                  gender,
                  serviceName,
                  duration,
                  date,
                  slots,
                  notes
                } = values;
                const [start_time, end_time] = slots.split(" - ");
                const userId = await getStorageData("user_id");
                const data = {
                  start_time: moment(`${date} ${start_time}`).format('YYYY-MM-DD HH:mm:ss'),
                  end_time: moment(`${date} ${end_time}`).format('YYYY-MM-DD HH:mm:ss'),
                  booking_date: date,
                  service_provider_id: userId,
                  timezone: "UTC",
                  catalogue_id: serviceName.id,
                  bx_block_categories_slot_id: duration.id,
                  full_name: name,
                  phone_number: phone,
                  country_code: 91,
                  additional_notes: notes,
                  email,
                  gender,
                };

                await this.createBooking(data);
              }}
            >
              {({
                handleChange,
                submitForm,
                errors,
                touched,
                handleBlur,
                setFieldValue,
                values
              }) => {
                const {
                  name,
                  phone,
                  gender,
                  email,
                  serviceName,
                  serviceCategory,
                  serviceSubCategory,
                  date,
                  duration,
                  slots,
                } = errors;
                const isNameError = this.getInputError(touched.name, name);
                const isPhoneError = this.getInputError(touched.phone, phone);
                const isGenderError = this.getInputError(touched.gender, gender);
                const isEmailError = this.getInputError(touched.email, email);
                const isServiceNameError = this.getInputError(!!touched.serviceName, serviceName?.name);
                const isServiceCategoryError = this.getInputError(!!touched.serviceCategory, serviceCategory?.name);
                const isServiceSubCategoryError = this.getInputError(!!touched.serviceSubCategory, serviceSubCategory?.name);
                const isDateError = this.getInputError(touched.date, date);
                const isDurationError = this.getInputError(!!touched.duration, duration?.duration);
                return (
                  <RegistrationFormView style={{ borderRadius: '8px' }}>
                    <Box className="mainView" style={{ backgroundColor: '#FFFFFF', borderBottom: 'none', borderRadius: '8px' }}>
                      <Typography className="title">Customer Information</Typography>
                      <Box >
                        <Box sx={{ display: 'flex', paddingTop: '15px', flexDirection: 'row', }}>
                          <InputLabel className="testLabel">Name</InputLabel>
                          <InputLabel className="testStar">*</InputLabel>
                        </Box>
                        <TextField
                          name='name'
                          fullWidth={true}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          variant="outlined"
                          className="inputeStyle"
                          data-test-id="customer-name"
                          placeholder={"Enter customer name"}
                          error={isNameError}
                          InputProps={{ style: { borderRadius: '8px' } }}
                        />
                        <Typography className="txtErrorMsg">{this.getErrorText(touched.name, name)}</Typography>
                      </Box>
                      <Box >
                        <Box sx={{ display: 'flex', paddingTop: '15px', flexDirection: 'row', }}>
                          <InputLabel className="testLabel">Phone Number</InputLabel>
                          <InputLabel className="testStar">*</InputLabel>
                        </Box>
                        <TextField
                          name='phone'
                          fullWidth={true}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          variant="outlined"
                          type="tel"
                          className="inputeStyle"
                          data-test-id="txtspaSummary"
                          placeholder={"Enter custormer contact number"}
                          error={isPhoneError}
                          size='medium'
                        />
                        <Typography className="txtErrorMsg">{this.getErrorText(touched.phone, phone)}</Typography>
                      </Box>
                      <Box >
                        <Box sx={{ display: 'flex', paddingTop: '15px', flexDirection: 'row', }}>
                          <InputLabel className="testLabel">Email ID</InputLabel>
                          <InputLabel className="testStar">*</InputLabel>
                        </Box>
                        <TextField
                          name='email'
                          fullWidth={true}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          variant="outlined"
                          className="inputeStyle"
                          data-test-id="txtspaSummary"
                          placeholder={"Enter email address"}
                          error={isEmailError}
                          multiline={true}
                          size='medium'
                          InputProps={{ style: { borderRadius: '8px' } }}
                        />
                        <Typography className="txtErrorMsg">{this.getErrorText(touched.email, email)}</Typography>
                      </Box>
                      <Box>
                        <Box sx={{ display: 'flex', paddingTop: '15px', flexDirection: 'row', }}>
                          <InputLabel className="testLabel">Gender</InputLabel>
                          <InputLabel className="testStar">*</InputLabel>
                        </Box>
                        <Select
                          name='gender'
                          data-test-id="gender-select"
                          fullWidth
                          variant="outlined"
                          value={values.gender}
                          className="input-booking-select"
                          displayEmpty
                          error={isGenderError}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          renderValue={this.renderInitSelect(values.gender, 'Select')}
                          IconComponent={ExpandMoreIcon}
                          MenuProps={{
                            anchorOrigin: {
                              vertical: "bottom",
                              horizontal: "left"
                            },
                            getContentAnchorEl: null,
                          }}
                        >
                          {genders.map((gender) => <MenuItem key={gender} value={gender}>{gender}</MenuItem>)}
                        </Select>
                        <Typography className="txtErrorMsg">{this.getErrorText(touched.gender, gender)}</Typography>
                      </Box>
                      <Box >
                        {/* Booking Details */}
                        <Box mt={4}>
                          <Typography className="title">Booking Details</Typography>
                          <Box>
                            <Box sx={{ display: 'flex', paddingTop: '15px', flexDirection: 'row', }}>
                              <InputLabel className="testLabel">Service Category</InputLabel>
                              <InputLabel className="testStar">*</InputLabel>
                            </Box>
                            <Autocomplete
                              data-test-id="service-category-input"
                              fullWidth
                              options={this.getSimpleCategories()}
                              getOptionLabel={(option) => option.name}
                              className='inputeStyle'
                              autoComplete
                              autoHighlight
                              disableClearable
                              disablePortal
                              onChange={(_, newValue) => {
                                setFieldValue("serviceSubCategory", {
                                  id: "0",
                                  name: "",
                                });
                                setFieldValue("serviceName", {
                                  id: "0",
                                  name: "",
                                });
                                setFieldValue("date", "");
                                setFieldValue("duration", {
                                  id: "0",
                                  duration: "",
                                });
                                setFieldValue("slots", "");
                                this.setSelectedSlot("");
                                this.setTimeSlots([])
                                setFieldValue("serviceCategory", newValue);
                              }}
                              onBlur={handleBlur('serviceCategory')}
                              popupIcon={<img width={25} height={25} src={arrowDown} alt="" />}
                              renderInput={(params: any) => (
                                <TextField
                                  {...params}
                                  placeholder='Select service category'
                                  error={isServiceCategoryError}
                                  InputProps={{
                                    ...params.InputProps,
                                    disableUnderline: true,
                                    style: this.getAutocompleteStyle(isServiceCategoryError)
                                  }}
                                />
                              )}
                            />
                            <Typography className="txtErrorMsg">{this.getErrorText(touched.serviceCategory, serviceCategory?.name)}</Typography>
                          </Box>
                          <Box>
                            <Box sx={{ display: 'flex', paddingTop: '15px', flexDirection: 'row', }}>
                              <InputLabel className="testLabel">Service Sub-Category</InputLabel>
                              <InputLabel className="testStar">*</InputLabel>
                            </Box>
                            <Autocomplete
                              data-test-id="service-subcategory-input"
                              fullWidth
                              disabled={!values.serviceCategory?.name}
                              options={this.getSubCategories(
                                values.serviceCategory?.id
                              )}
                              value={values.serviceSubCategory}
                              getOptionLabel={(option) => option.name}
                              className='inputeStyle'
                              autoComplete
                              autoHighlight
                              disableClearable
                              disablePortal
                              onChange={async (_, newValue) => {
                                setFieldValue("serviceSubCategory", newValue);
                                setFieldValue("serviceName", {
                                  id: "0",
                                  name: "",
                                });
                                setFieldValue("date", "");
                                setFieldValue("duration", {
                                  id: "0",
                                  duration: "",
                                });
                                setFieldValue("slots", "");
                                this.setSelectedSlot("");
                                this.setTimeSlots([])
                                await this.getServices(
                                  values.serviceCategory.id,
                                  newValue.id
                                );
                              }}
                              onBlur={handleBlur('serviceSubCategory')}
                              popupIcon={<img width={25} height={25} src={arrowDown} alt="" />}
                              renderInput={(params: any) => (
                                <TextField
                                  {...params}
                                  placeholder='Select service sub-category'
                                  error={isServiceSubCategoryError}
                                  InputProps={{
                                    ...params.InputProps,
                                    disableUnderline: true,
                                    style: this.getAutocompleteStyle(isServiceSubCategoryError)
                                  }}
                                />
                              )}
                            />
                            <Typography className="txtErrorMsg">{this.getErrorText(touched.serviceSubCategory, serviceSubCategory?.name)}</Typography>
                          </Box>
                          <Box>
                            <Box sx={{ display: 'flex', paddingTop: '15px', flexDirection: 'row', }}>
                              <InputLabel className="testLabel">Service Name</InputLabel>
                              <InputLabel className="testStar">*</InputLabel>
                            </Box>
                            <Autocomplete
                              data-test-id="service-name-input"
                              fullWidth
                              options={this.state.servicesOptions}
                              value={values.serviceName}
                              disabled={!values.serviceSubCategory?.name}
                              getOptionLabel={(option) => option.name}
                              className='inputeStyle'
                              autoComplete
                              autoHighlight
                              disableClearable
                              disablePortal
                              loading={this.state.servicesLoading}
                              onChange={(_, newValue) => {
                                setFieldValue("serviceName", newValue);
                                setFieldValue("date", "");
                                setFieldValue("duration", {
                                  id: "0",
                                  duration: "",
                                });
                                setFieldValue("slots", "");
                                this.setSelectedSlot("");
                                this.setTimeSlots([])

                                const firstAvailability =
                                  ((this.setSelectedService(newValue.id) || initCatalogues[0]).attributes.availabilities as any).find(
                                    (availability: any) => availability.duration
                                  );

                                const initAttribs =
                                  initCatalogues[0].attributes;
                                const { dates } =
                                  firstAvailability ||
                                  initAttribs.availabilities[0];

                                this.setSlotDays(dates);
                              }}
                              onBlur={handleBlur('serviceName')}
                              popupIcon={<img width={25} height={25} src={arrowDown} alt="" />}
                              renderInput={(params: any) => (
                                <TextField
                                  {...params}
                                  placeholder="Select service name"
                                  error={isServiceNameError}
                                  InputProps={{
                                    ...params.InputProps,
                                    disableUnderline: true,
                                    style:
                                      this.getAutocompleteStyle(
                                        isServiceNameError
                                      ),
                                  }}
                                />
                              )}
                            />
                            <Typography className="txtErrorMsg">
                              {this.getErrorText(touched.serviceName, serviceName?.name)}
                            </Typography>
                          </Box>
                          <Box display='flex' gridGap={8}>
                            {/* DatePicker */}
                            <Box width='100%'>
                              <Box
                                sx={{
                                  display: "flex",
                                  paddingTop: "15px",
                                  flexDirection: "row",
                                }}
                              >
                                <InputLabel className="testLabel">Date</InputLabel>
                                <InputLabel className="testStar">*</InputLabel>
                              </Box>
                              <Box position='relative'>
                                <img
                                  src={calendar}
                                  alt="calendar"
                                  style={{
                                    position: 'absolute',
                                    cursor: 'pointer',
                                    height: 24,
                                    width: 24,
                                    top: '50%',
                                    transform: 'translateY(-50%)',
                                    marginLeft: 8
                                  }}
                                  data-test-id="date-picker-toggle"
                                  onClick={
                                    this.calendarSlotRef.current?.openCalendar
                                  }
                                />
                                <DatePicker
                                  // data-test-id is not applied here for tests purpose
                                  className="basic-page-picker"
                                  ref={this.calendarSlotRef}
                                  disabled={!values.serviceName.name}
                                  placeholder="Select date"
                                  inputClass="input-date-style"
                                  format="YYYY-MM-DD"
                                  monthYearSeparator=" "
                                  value={values.date}
                                  weekStartDayIndex={1}
                                  fixMainPosition
                                  calendarPosition="bottom-end"
                                  offsetX={120}
                                  mapDays={({ date: dateItem }) => {
                                    if (
                                      !this.state.slotDays.includes(
                                        dateItem.format("ddd")
                                      )
                                    ) {
                                      return {
                                        disabled: true,
                                        style: { color: "#ccc" },
                                      };
                                    }
                                  }}
                                  onChange={
                                    (dateItem: DateObject) => {
                                      setFieldValue(
                                        "date",
                                        dateItem.format("YYYY-MM-DD")
                                      );
                                      setFieldValue("duration", {
                                        id: "0",
                                        duration: "",

                                      });
                                      setFieldValue("slots", "");
                                      this.setSelectedSlot("");
                                      this.setTimeSlots([])
                                    }
                                  }
                                />
                              </Box>
                              <Typography className="txtErrorMsg">{this.getErrorText(touched.date, date)}</Typography>
                            </Box>
                            {/* Duration */}
                            <Box width='100%'>
                              <Box sx={{ display: 'flex', paddingTop: '15px', flexDirection: 'row', }}>
                                <InputLabel className="testLabel">Duration</InputLabel>
                                <InputLabel className="testStar">*</InputLabel>
                              </Box>
                              <Autocomplete
                                data-test-id="duration-input"
                                fullWidth
                                options={
                                  ((this.state.selectedService || initCatalogues[0]) as any).attributes.availabilities.map(
                                    (availability: any) => {
                                      const { id, duration } = availability.duration;
                                      return { id, duration }
                                    })
                                }
                                getOptionLabel={(option) => option?.duration || ""}
                                value={values.duration}
                                disabled={!values.date}
                                className='inputeStyle'
                                autoComplete
                                autoHighlight
                                disableClearable
                                disablePortal
                                onChange={(_, newValue) => {
                                  const targetTimeSlots = this.state.selectedService?.attributes.availabilities.find(
                                    (avail) => avail.duration.id === newValue.id
                                  )?.timeslots;
                                  this.generateTimeSlots(newValue.duration, targetTimeSlots);
                                  setFieldValue("duration", {
                                    id: newValue.id,
                                    duration: newValue.duration,
                                  });
                                  // Reset selected slots
                                  setFieldValue("slots", "");
                                  this.setSelectedSlot("");
                                }}
                                onBlur={handleBlur('duration')} popupIcon={<img width={25} height={25} src={arrowDown} alt="" />}
                                renderInput={(params: any) => (
                                  <TextField
                                    {...params}
                                    placeholder='Select duration'
                                    error={isDurationError}
                                    InputProps={{
                                      ...params.InputProps,
                                      disableUnderline: true,
                                      style: this.getAutocompleteStyle(isDurationError),
                                      startAdornment: (
                                        <InputAdornment position="start">
                                          <img width={20} height={20} src={imgPendingClockGrey} alt="pending" />
                                        </InputAdornment>
                                      )
                                    }}
                                  />
                                )}
                              />
                              <Typography className="txtErrorMsg">{this.getErrorText(touched.duration, duration?.duration)}</Typography>
                            </Box>
                          </Box>
                          {/* Time slots */}
                          <Box>
                            <Box sx={{ display: 'flex', paddingTop: '15px', flexDirection: 'row', }}>
                              <InputLabel className="testLabel">Available Slots</InputLabel>
                              <InputLabel className="testStar">*</InputLabel>
                            </Box>
                            <Box display='flex' gridGap={16} flexWrap='wrap'>
                              {this.state.timeSlots.map((slot, index) => (
                                <Chip
                                  data-test-id={`chip-${index}`}
                                  className="chip-item"
                                  label={slot}
                                  variant="outlined"
                                  key={slot}
                                  style={{
                                    height: '50px',
                                    width: '15%',
                                    borderRadius: '8px',
                                    border: '1px solid #398378',
                                    fontSize: '16px',
                                    cursor: 'pointer',
                                    ...this.getHighlightSlotStyle(slot)
                                  }}
                                  onClick={() => {
                                    setFieldValue("slots", slot);
                                    this.setSelectedSlot(slot);
                                  }}
                                />
                              ))}
                            </Box>
                            <Typography className="txtErrorMsg">{this.getErrorText(touched.slots, slots)}</Typography>
                          </Box>
                          <Box >
                            <Box sx={{ display: 'flex', paddingTop: '15px', flexDirection: 'row', }}>
                              <InputLabel className="testLabel">Additional Notes</InputLabel>
                            </Box>
                            <TextField
                              name='notes'
                              fullWidth={true}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              variant="outlined"
                              className="inputeStyle"
                              data-test-id="txtInputSpaName"
                              placeholder={"Write additional notes"}
                              multiline={true}
                              inputProps={{
                                style: {
                                  height: "100px",
                                },
                              }}
                            />
                          </Box>
                        </Box>
                      </Box>
                    </Box>

                    <Box className="locationViewStyle" width={'94%'}>
                      <ButtonComponent>
                        <Button
                          data-test-id='back-btn'
                          variant="contained"
                          color="primary"
                          onClick={() => this.gotoMyBooking()}
                          className="regButton"
                          style={{
                            background: 'var(--Primary, #D9EEEB)',
                            color: '#398378',
                          }}
                        >
                          Cancel
                        </Button>
                        <Button
                          data-test-id='submit-btn'
                          variant="contained"
                          color="primary"
                          onClick={submitForm}
                          className="regButton"
                        >
                          Add Booking
                        </Button>
                      </ButtonComponent>
                    </Box>
                  </RegistrationFormView>
                )
              }}
            </Formik>

          </Box>
          {/* footer */}
          <AppFooter navigation={this.props.navigation} />
        </Box>
      </TypographyView >
      // Customizable Area End
    );
  }
}

// Customizable Area Start
// Customizable Area End
